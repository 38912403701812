var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 50 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-arrow-left" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "Dashboard" })
                    }
                  }
                },
                [_vm._v("Zurück")]
              ),
              _c("h1", [_vm._v(_vm._s(this.$route.params.name))]),
              [
                _vm._v(" Status: "),
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type:
                        _vm.showAktivStatus().isActive == true
                          ? "success"
                          : "danger",
                      "disable-transitions": ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.showAktivStatus().status))]
                )
              ],
              _c("el-divider"),
              _c("h3", [_vm._v("Modul-Konfiguration")]),
              _c(
                "el-card",
                [
                  _c("el-switch", {
                    attrs: { "active-text": "Umfragen" },
                    on: {
                      change: function($event) {
                        return _vm.updateConfig($event, "surveys")
                      }
                    },
                    model: {
                      value: _vm.config.surveys,
                      callback: function($$v) {
                        _vm.$set(_vm.config, "surveys", $$v)
                      },
                      expression: "config.surveys"
                    }
                  })
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c(
                "el-card",
                [
                  _c("el-switch", {
                    attrs: { "active-text": "Feedback Box" },
                    on: {
                      change: function($event) {
                        return _vm.updateConfig($event, "feedback-box")
                      }
                    },
                    model: {
                      value: _vm.config.feedbackBox,
                      callback: function($$v) {
                        _vm.$set(_vm.config, "feedbackBox", $$v)
                      },
                      expression: "config.feedbackBox"
                    }
                  }),
                  _c("el-button", {
                    staticClass: "configButton",
                    attrs: { icon: "el-icon-s-tools", circle: "" },
                    on: {
                      click: function($event) {
                        _vm.dialogFeedbackBoxVisible = true
                      }
                    }
                  })
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c(
                "el-card",
                [
                  _c("el-switch", {
                    attrs: { "active-text": "Neuigkeiten u. News" },
                    on: {
                      change: function($event) {
                        return _vm.updateConfig($event, "feedback-box")
                      }
                    },
                    model: {
                      value: _vm.config.news,
                      callback: function($$v) {
                        _vm.$set(_vm.config, "news", $$v)
                      },
                      expression: "config.news"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  staticClass: "moduleConfig",
                  attrs: {
                    width: "50%",
                    title: "FeedbackBox Konfiguration",
                    visible: _vm.dialogFeedbackBoxVisible
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogFeedbackBoxVisible = $event
                    }
                  }
                },
                [
                  _c("span", [_vm._v("Konfiguration:")]),
                  _c("br"),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: { "active-text": "Peer-to-Peer Feedback erlauben" },
                    model: {
                      value: _vm.moduleConfig.feedbackBox.allowPeerFeedback,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "allowPeerFeedback",
                          $$v
                        )
                      },
                      expression: "moduleConfig.feedbackBox.allowPeerFeedback"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: { "active-text": "Peer-to-Team Feedback erlauben" },
                    model: {
                      value: _vm.moduleConfig.feedbackBox.allowPeerTeamFeedback,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "allowPeerTeamFeedback",
                          $$v
                        )
                      },
                      expression:
                        "moduleConfig.feedbackBox.allowPeerTeamFeedback"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: { "active-text": "FeedbackBoard aktivieren" },
                    model: {
                      value: _vm.moduleConfig.feedbackBox.enableFeedbackBoard,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "enableFeedbackBoard",
                          $$v
                        )
                      },
                      expression: "moduleConfig.feedbackBox.enableFeedbackBoard"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: { "active-text": "Public Feedback erlauben" },
                    model: {
                      value: _vm.moduleConfig.feedbackBox.allowPublicFeedback,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "allowPublicFeedback",
                          $$v
                        )
                      },
                      expression: "moduleConfig.feedbackBox.allowPublicFeedback"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: { "active-text": "Anonymes Feedback erlauben" },
                    model: {
                      value:
                        _vm.moduleConfig.feedbackBox.allowAnonymousFeedback,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "allowAnonymousFeedback",
                          $$v
                        )
                      },
                      expression:
                        "moduleConfig.feedbackBox.allowAnonymousFeedback"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: { "active-text": "'Demonstrated Values' erlauben" },
                    model: {
                      value:
                        _vm.moduleConfig.feedbackBox.allowDemonstratedValues,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "allowDemonstratedValues",
                          $$v
                        )
                      },
                      expression:
                        "moduleConfig.feedbackBox.allowDemonstratedValues"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: {
                      "active-text":
                        "Reaktionen zu öffentlichen Feedback erlauben"
                    },
                    model: {
                      value:
                        _vm.moduleConfig.feedbackBox
                          .allowReactionsToPublicFeedback,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "allowReactionsToPublicFeedback",
                          $$v
                        )
                      },
                      expression:
                        "moduleConfig.feedbackBox.allowReactionsToPublicFeedback"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: { "active-text": "Auto-Checkins erlauben" },
                    model: {
                      value: _vm.moduleConfig.feedbackBox.enableAutoCheckins,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "enableAutoCheckins",
                          $$v
                        )
                      },
                      expression: "moduleConfig.feedbackBox.enableAutoCheckins"
                    }
                  }),
                  _c("br"),
                  _c("el-switch", {
                    staticClass: "switch-option",
                    attrs: {
                      "active-text":
                        "Feedback an Organisations-Administrator erlauben"
                    },
                    model: {
                      value:
                        _vm.moduleConfig.feedbackBox.allowFeedbackToOrgAdmin,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "allowFeedbackToOrgAdmin",
                          $$v
                        )
                      },
                      expression:
                        "moduleConfig.feedbackBox.allowFeedbackToOrgAdmin"
                    }
                  }),
                  _c("br"),
                  _c("br"),
                  _c("span", [_vm._v("Demonstrated Values:")]),
                  _c("br"),
                  _c("br"),
                  _c("el-select", {
                    staticClass: "coreValueSelect",
                    attrs: {
                      multiple: "",
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      placeholder: "Values eingeben ..."
                    },
                    model: {
                      value: _vm.moduleConfig.feedbackBox.demonstratedValues,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moduleConfig.feedbackBox,
                          "demonstratedValues",
                          $$v
                        )
                      },
                      expression: "moduleConfig.feedbackBox.demonstratedValues"
                    }
                  }),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.changeModuleConfigDialog(
                                "feedbackBox",
                                "save"
                              )
                            }
                          }
                        },
                        [_vm._v("Speichern")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.changeModuleConfigDialog(
                                "feedbackBox",
                                "cancel"
                              )
                            }
                          }
                        },
                        [_vm._v("Abbrechen")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              this.onlyAdmins !== "undefined" && this.onlyAdmins.length > 0
                ? _c(
                    "div",
                    [
                      _c("h3", [_vm._v("Organisations-Adminstratoren")]),
                      _c(
                        "el-table",
                        {
                          ref: "filterTable",
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.onlyAdmins }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "Name", width: "180" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "email", label: "E-Mail" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c("div", [
                    _c("h3", [_vm._v("Keine Adminstratoren im Unternehmen")])
                  ]),
              _c("el-divider"),
              this.onlyMembers !== "undefined" && this.onlyMembers.length > 0
                ? _c(
                    "div",
                    [
                      _c("h3", [_vm._v("Mitglieder")]),
                      _c(
                        "el-table",
                        {
                          ref: "filterTable",
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.onlyMembers }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "Name", width: "180" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "email", label: "E-Mail" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c("div", [
                    _c("h3", [_vm._v("Keine Mitarbeiter im Unternehmen")])
                  ])
            ],
            2
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "SideBarCard",
                { attrs: { title: "Übersicht" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("h1", { staticClass: "m-b-0 m-t-0" }, [
                          _vm._v(_vm._s(_vm.onlyMembers.length))
                        ]),
                        _c("p", { staticClass: "m-t-0 m-b-1" }, [
                          _vm._v("Mitarbeiter")
                        ])
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("h1", { staticClass: "m-b-0 m-t-0" }, [
                          _vm._v(_vm._s(_vm.onlyAdmins.length))
                        ]),
                        _c("p", { staticClass: "m-t-0 m-b-1" }, [
                          _vm._v("Admins")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "SideBarCard",
                { attrs: { title: "Aktionen" } },
                [
                  _c("el-divider"),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-edit" },
                      on: {
                        click: function($event) {
                          _vm.drawer = true
                        }
                      }
                    },
                    [_vm._v("Bearbeiten")]
                  ),
                  _c("br"),
                  _c("br"),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        plain: "",
                        icon: "el-icon-message"
                      }
                    },
                    [_vm._v("Kontaktieren")]
                  ),
                  _c("el-divider"),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        plain: "",
                        icon: "el-icon-delete",
                        loading: _vm.isLoadingRemove
                      },
                      on: {
                        click: function($event) {
                          return _vm.openDeleteDialogue()
                        }
                      }
                    },
                    [_vm._v("Organisation löschen ...")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }