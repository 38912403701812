var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { staticClass: "sidebarCard" },
    [
      _c("div", {
        staticClass: "accentElement",
        style: "background:" + _vm.props.accent
      }),
      _c(
        "p",
        { staticClass: "cardHeading", style: "color:" + _vm.props.color },
        [_vm._v(_vm._s(_vm.props.title))]
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }