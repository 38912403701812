<template>
  <div>
    <el-row :gutter="50">
      <el-col :span="16">
        <el-button icon="el-icon-arrow-left" @click="$router.push({ name: 'Dashboard' })">Zurück</el-button>

        <!-- Show isActive indicator and organisation name-->
        <h1>{{this.$route.params.name}}</h1>
        <template>
          Status:
          <el-tag
            :type="showAktivStatus().isActive == true ? 'success' : 'danger'"
            disable-transitions
          >{{showAktivStatus().status}}</el-tag>
        </template>

        <el-divider></el-divider>

        <h3>Modul-Konfiguration</h3>
        <el-card>
          <el-switch v-model="config.surveys" @change="updateConfig($event, 'surveys')" active-text="Umfragen"/>
        </el-card>
        <br /><br />
        <el-card>
          <el-switch v-model="config.feedbackBox" @change="updateConfig($event, 'feedback-box')" active-text="Feedback Box"></el-switch>
          <el-button class="configButton" @click="dialogFeedbackBoxVisible = true" icon="el-icon-s-tools" circle></el-button>
        </el-card>
        <br /><br />
        <el-card>
          <el-switch v-model="config.news" @change="updateConfig($event, 'feedback-box')" active-text="Neuigkeiten u. News" />
        </el-card>
        <el-dialog
          class="moduleConfig"
          width="50%"
          title="FeedbackBox Konfiguration"
          :visible.sync="dialogFeedbackBoxVisible">
          <span>Konfiguration:</span>
          <br /><br />
          <el-switch v-model="moduleConfig.feedbackBox.allowPeerFeedback" class="switch-option"  active-text="Peer-to-Peer Feedback erlauben"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.allowPeerTeamFeedback" class="switch-option"  active-text="Peer-to-Team Feedback erlauben"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.enableFeedbackBoard" class="switch-option" active-text="FeedbackBoard aktivieren"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.allowPublicFeedback" class="switch-option"  active-text="Public Feedback erlauben"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.allowAnonymousFeedback" class="switch-option"  active-text="Anonymes Feedback erlauben"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.allowDemonstratedValues" class="switch-option"  active-text="'Demonstrated Values' erlauben"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.allowReactionsToPublicFeedback" class="switch-option"  active-text="Reaktionen zu öffentlichen Feedback erlauben"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.enableAutoCheckins" class="switch-option" active-text="Auto-Checkins erlauben"/><br />
          <el-switch v-model="moduleConfig.feedbackBox.allowFeedbackToOrgAdmin" class="switch-option" active-text="Feedback an Organisations-Administrator erlauben"/><br />
          <br />
          <span>Demonstrated Values:</span>
          <br /><br />
          <el-select
          class="coreValueSelect"
            v-model="moduleConfig.feedbackBox.demonstratedValues"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="Values eingeben ...">
          </el-select>
          <br /><br /><br />
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="changeModuleConfigDialog('feedbackBox', 'save')">Speichern</el-button>
            <el-button type="primary" @click="changeModuleConfigDialog('feedbackBox', 'cancel')">Abbrechen</el-button>
          </span>
        </el-dialog>

        <!-- Show all admins of the organisation -->
        <div v-if="this.onlyAdmins !== 'undefined' && this.onlyAdmins.length > 0">
          <h3>Organisations-Adminstratoren</h3>
          <el-table ref="filterTable" :data="onlyAdmins" style="width: 100%">
            <el-table-column prop="name" label="Name" width="180"></el-table-column>
            <el-table-column prop="email" label="E-Mail"></el-table-column>
          </el-table>
        </div>
        <div v-else>
          <!-- Placeholder -->
          <h3>Keine Adminstratoren im Unternehmen</h3>
        </div>
        <el-divider></el-divider>
        <!-- Show all members of the organisation -->
        <div v-if="this.onlyMembers !== 'undefined' && this.onlyMembers.length > 0">
          <h3>Mitglieder</h3>
          <el-table ref="filterTable" :data="onlyMembers" style="width: 100%">
            <el-table-column prop="name" label="Name" width="180"></el-table-column>
            <el-table-column prop="email" label="E-Mail"></el-table-column>
          </el-table>
        </div>
        <div v-else>
          <!-- Placeholder -->
          <h3>Keine Mitarbeiter im Unternehmen</h3>     
        </div>
      </el-col>

      <!-- Sidecard with member counter -->
      <el-col :span="8">
        <SideBarCard title="Übersicht">
          <el-row type="flex">
            <el-col :span="12">
              <h1 class="m-b-0 m-t-0">{{onlyMembers.length}}</h1>
              <p class="m-t-0 m-b-1">Mitarbeiter</p>
            </el-col>
            <el-col :span="12">
              <h1 class="m-b-0 m-t-0">{{onlyAdmins.length}}</h1>
              <p class="m-t-0 m-b-1">Admins</p>
            </el-col>
          </el-row>
        </SideBarCard>

        <!-- Sidecard with actions -->
        <SideBarCard title="Aktionen">
          <el-divider></el-divider>
          <el-button @click="drawer = true" type="primary" icon="el-icon-edit">Bearbeiten</el-button><br /><br />
          <el-button type="info" plain icon="el-icon-message">Kontaktieren</el-button>
          <el-divider></el-divider>
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            @click="openDeleteDialogue()"
            :loading="isLoadingRemove"
          >Organisation löschen ...</el-button>
        </SideBarCard>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SideBarCard from "@/components/Layout/SideBarCard";
export default {
  components: { SideBarCard },
  data() {
    return {
      isLoadingRemove: false,
      drawer: false,

        organisationId: "",
        config: {
          surveys: false,
          feedbackBox: false,
          news: false
        },
        moduleConfig: {
          feedbackBox: {
            allowPeerFeedback: false,
            allowPeerTeamFeedback: false,
            enableFeedbackBoard: false,
            allowPublicFeedback: false,
            allowAnonymousFeedback: false,
            demonstratedValues: [],
            allowDemonstratedValues: false,
            allowReactionsToPublicFeedback: false,
            enableAutoCheckins: false,
            allowFeedbackToOrgAdmin: false
          }
        },
      dialogFeedbackBoxVisible: false,
    };
  },
  computed: {
    ...mapState({ organisations: state => state.organisations.list }),
    ...mapState({ members: state => state.organisations.organisationMembers }),

    // Dummy Object for reactive data population
    org() {
      let defaultOrg = {}
      console.log(this.organisations[this.organisationId])
      return { ...defaultOrg, ...this.organisations[this.organisationId] }; // Populate data with vuex state
    },
    // Transform object to array with all members and admins of an organisation
    allMemberArray() {
      let allMemberArray = [];
      Object.keys(this.members).forEach(id => {
        allMemberArray.push({
          id: id,
          ...this.members[id]
        });
      });
      return allMemberArray;
    },

    // Array, only with admins of current organisation
    onlyAdmins() {
      let onlyAdmins = [];
      let currentSelection = [{ name: "", email: "" }];
      for (var i = 0; i < this.allMemberArray.length; i++) {
        if (this.allMemberArray[i].role == "admin") {
          currentSelection.name =
            this.allMemberArray[i].fname + " " + this.allMemberArray[i].lname;
          currentSelection.email = this.allMemberArray[i].email;
          onlyAdmins.push(currentSelection);
          currentSelection = [{ name: "", email: "" }];
        } else {
          currentSelection = [{ name: "", email: "" }];
        }
      }
      return onlyAdmins;
    },

    // Array, only with members of current organisation
    onlyMembers() {
      let onlyMembers = [];
      let currentSelection = [{ name: "", email: "" }];
      for (var i = 0; i < this.allMemberArray.length; i++) {
        if (this.allMemberArray[i].role === "member") {
          currentSelection.name =
            this.allMemberArray[i].fname + " " + this.allMemberArray[i].lname;
          currentSelection.email = this.allMemberArray[i].email;
          onlyMembers.push(currentSelection);
          currentSelection = [];
        }
      }
      return onlyMembers;
    }
  },

  created() {
    this.fetchInitialData();
  },

  destroyed() {
    this.$store.dispatch("updateMemberList", false); // Clear current memberlist
  },

  methods: {
    async fetchInitialData() {
      // Fetch initial data
      let organisationId = this.$route.params.id;
      this.organisationId = organisationId;
      await this.$store.dispatch("updateOrganisationList");
      await this.$store.dispatch("updateMemberList", organisationId);
      await this.$store.dispatch("getOrganisation", organisationId);
      this.loadInitialConfig();
    },
    // Delete dialogue to prevent accidential deletion
    openDeleteDialogue() {
      this.$confirm("Organisation wirklich endgültig löschen?", "Warnung", {
        confirmButtonText: "Löschen",
        cancelButtonText: "Abbrechen",
        type: "warning"
      })
        .then(() => {
          this.deleteOrganisation();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Löschen abgebrochen"
          });
        });
    },

    // Deletes current organisation
    async deleteOrganisation() {
      this.isLoadingRemove = true;
      await this.$store.dispatch("deleteOrganisation", {
        organisationId: this.organisationId
      });
      this.isLoadingRemove = false;
      this.$router.push({ name: "Dashboard" });
    },

    // Return organisations status (identified by param.) as object including bool and string
    showAktivStatus() {
      if (this.org.isActive == true) {
        return { isActive: true, status: "AKTIV" };
      } else {
        return { isActive: false, status: "INAKTIV" };
      }
    },
    async updateConfig(event, module) {
      // Update config of modules in db
      let newConfig = [];

      if (this.config.surveys == true) {
        newConfig.push("surveys");
      }
      if (this.config.feedbackBox == true) {
        newConfig.push("feedback-box");
      }
      if (this.config.news == true) {
        newConfig.push("news");
      }

      this.$store.dispatch("updateConfig", {
        organisationId: this.organisationId,
        config: newConfig
      });

      let response = await this.$store.dispatch("updateConfig", {
        organisationId: this.organisationId,
        config: newConfig
      });
          // Check response
          if (response.code == 200) {
            this.$notify({
              title: "Einstellung wurde erfolgreich gespeichert.",
              message: "",
              type: "success"
            });
          } else {
            this.$notify.error({
              title: "Fehler.",
              message:
                "Fehler beim Speichern der Einstelllungen."
            });
          }
    },

    async updateModuleConfig(selectedModule){
      let newConfig = [];
      if (selectedModule == "feedbackBox"){
        let moduleConfig = {
          moduleConfig: this.moduleConfig
        }
        let response = await this.$store.dispatch("updateModuleConfig",{
          organisationId: this.organisationId,
          moduleConfig: moduleConfig
        })
          // Check response
          if (response.code == 200) {
            this.$notify({
              title: "Einstellung wurde erfolgreich gespeichert.",
              message: "",
              type: "success"
            });
          } else {
            this.$notify.error({
              title: "Fehler.",
              message:
                "Fehler beim Speichern der Einstelllungen."
            });
          }
        this.fetchInitialData()
      }
    },
    changeModuleConfigDialog(selectedModule, exitMethode){
      if(selectedModule == "feedbackBox"){
        if(exitMethode == "save"){
          this.updateModuleConfig('feedbackBox')
        }
        else if( exitMethode == "cancel"){
          this.loadInitialConfig();
        }
        this.dialogFeedbackBoxVisible = false;
      }
    },
    loadInitialConfig() {
      if(this.org == undefined){
        console.log("INITIAL")
        console.log(this.org)
        return
      }
      // Check config and adjust local state accordingly
      if (this.org.config.includes("surveys")) {
        this.config.surveys = true;
      }
      if (this.org.config.includes("feedback-box")) {
        this.config.feedbackBox = true;
      }
      if (this.org.config.includes("news")) {
        this.config.news = true;
      }
      // ModuleConfig
      if(this.org.moduleConfig.feedbackBox != undefined){
        // Clone objects data from store but not objects reference --- Otherwise state would be change outside mutation
        this.moduleConfig.feedbackBox.allowPeerFeedback = this.org.moduleConfig.feedbackBox.allowPeerFeedback
        this.moduleConfig.feedbackBox.allowPeerTeamFeedback = this.org.moduleConfig.feedbackBox.allowPeerTeamFeedback
        this.moduleConfig.feedbackBox.enableFeedbackBoard = this.org.moduleConfig.feedbackBox.enableFeedbackBoard
        this.moduleConfig.feedbackBox.allowPublicFeedback = this.org.moduleConfig.feedbackBox.allowPublicFeedback
        this.moduleConfig.feedbackBox.allowAnonymousFeedback = this.org.moduleConfig.feedbackBox.allowAnonymousFeedback
        this.moduleConfig.feedbackBox.demonstratedValues = this.org.moduleConfig.feedbackBox.demonstratedValues
        this.moduleConfig.feedbackBox.allowDemonstratedValues = this.org.moduleConfig.feedbackBox.allowDemonstratedValues
        this.moduleConfig.feedbackBox.allowReactionsToPublicFeedback = this.org.moduleConfig.feedbackBox.allowReactionsToPublicFeedback
        this.moduleConfig.feedbackBox.enableAutoCheckins = this.org.moduleConfig.feedbackBox.enableAutoCheckins
        this.moduleConfig.feedbackBox.allowFeedbackToOrgAdmin = this.org.moduleConfig.feedbackBox.allowFeedbackToOrgAdmin

      }
    }
  }
};
</script>

<style scoped>
.configButton{
  margin: 0;
  display: inline-block;
  margin-left: 10px;
}
.switch-option{
margin-bottom: 5px;
}
.coreValueSelect{
  width: 100%;
}
</style>